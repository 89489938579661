import { createType, createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createType([
    'SAVETOKEN',
  ]),
  ...createApiType([
    'GET_IS_VOTE_ADMIN',
    'GET_ALIASES',
  ]),
};

export default {
  USER_TOKEN({ commit }, { token, tokenExpiredAt }) {
    commit(Types.SAVETOKEN, { token, tokenExpiredAt });
  },
  [Types.GET_IS_VOTE_ADMIN]({ dispatch }, {
    chatID,
    aliasID,
    onSuccess,
    onFailed,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_IS_VOTE_ADMIN),
      method: 'get',
      uri: `${process.env.VUE_APP_API_HOST}/chat/${chatID}/isVoteAdmin?aliasID=${aliasID}`,
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
  [Types.GET_ALIASES]({ dispatch }, {
    openID,
    onSuccess,
    onFailed,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_ALIASES),
      method: 'get',
      uri: `${process.env.VUE_APP_API_HOST}/openid/${openID}/aliases`,
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
