import Auth from './auth';
import GetVoteList from './getVoteList';
import GetNewSubjectId from './getNewSubjectId';
import CreateVote from './createVote';
import EditVote from './editVote';
import DeleteVote from './deleteVote';
import GetVoteData from './getVoteData';
import GetIsVoted from './getIsVoted';
import Vote from './vote';
import GetVoteResult from './getVoteResult';
import EndVote from './endVote';
import File from './file';
import GetIsPinned from './getIsPinned';
import SetPinned from './setPinned';
import GetVoteListBySubjectType from './getVoteListBySubjectType';
import News from './news';
import Comment from './comment';

export default {
  Auth,
  GetVoteList,
  GetNewSubjectId,
  CreateVote,
  EditVote,
  DeleteVote,
  GetVoteData,
  GetIsVoted,
  Vote,
  GetVoteResult,
  EndVote,
  File,
  GetIsPinned,
  SetPinned,
  GetVoteListBySubjectType,
  News,
  Comment,
};
