import RouteMeta from '../RouteMeta';

export default {
  path: '/news/voteList',
  name: 'newsVoteList',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/news/VoteList.vue'),
  props: true,
  meta: new RouteMeta({
    auth: false,
  }),
};
