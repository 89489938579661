import {
  Question,
  Option,
} from '@/store/entity/response';
import { Types } from './actions';

export const initState = {
  subjectID: null,
  title: '',
  imageUri: null,
  description: '',
  type: null,
  isActive: null,
  startTime: null,
  endTime: null,
  chatID: null,
  version: 1,
  createTime: null,
  createdUser: null,
  createdUserName: null,
  modifiedTime: null,
  modifiedUser: null,
  isPinned: null,
  questionList: [],
  votePeopleCount: null,
  dueDays: null,
  requesting: false,
};

export const mutations = {
  [Types.GET_VOTE_DATA_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_VOTE_DATA_SUCCESS](state, payload) {
    state.requesting = false;

    state.subjectID = payload.subjectID;
    state.title = payload.title;
    state.imageUri = payload.imageUri;
    state.description = payload.description;
    state.type = payload.type; // enum
    state.isActive = payload.isActive;
    state.isPinned = payload.isPinned;
    state.startTime = payload.startTime;
    state.endTime = payload.endTime;
    state.chatID = payload.chatID;
    state.version = payload.version;
    state.createTime = payload.createTime;
    state.createdUser = payload.createdUser;
    state.createdUserName = payload.createdUserName;
    state.modifiedTime = payload.modifiedTime;
    state.modifiedUser = payload.modifiedUser;
    state.questionList = [];
    // state.votePeopleCount = null; // getVoteResp 無回傳
    // state.dueDays = null; // getVoteResp 無回傳

    for (let i = 0; i < payload.questionList.length; i += 1) {
      const question = new Question({
        title: payload.questionList[i].title,
        mode: payload.questionList[i].mode, // enum
        type: payload.questionList[i].type, // enum
        isMultipleVote: payload.questionList[i].isMultipleVote,
        sortID: payload.questionList[i].sortID,
        optionList: [],
      });

      for (let q = 0; q < payload.questionList[i].optionList.length; q += 1) {
        const option = new Option({
          optionID: payload.questionList[i].optionList[q].optionID,
          title: payload.questionList[i].optionList[q].title,
          imageUri: payload.questionList[i].optionList[q].imageUri,
          sortID: payload.questionList[i].optionList[q].sortID,
          votingRate: payload.questionList[i].optionList[q].votingRate,
          isVoted: payload.questionList[i].optionList[q].isSelected,
        });
        question.optionList.push(option);
      }

      state.questionList.push(question);
    }
  },
  [Types.GET_VOTE_DATA_FAILED](state) {
    state.requesting = false;
  },
};
