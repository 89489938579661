import RouteMeta from '../RouteMeta';

export default {
  path: '/news/voteResult',
  name: 'newsVoteResult',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/news/VoteResult.vue'),
  props: true,
  meta: new RouteMeta({
    auth: false,
  }),
};
