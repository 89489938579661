import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'GET_VOTE_LIST_BY_SUBJECT_TYPE',
  ]),
};

export default {
  [Types.GET_VOTE_LIST_BY_SUBJECT_TYPE]({ dispatch }, {
    subjectType,
    aliasID,
    onSuccess = null,
    onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_VOTE_LIST_BY_SUBJECT_TYPE),
      method: 'get',
      uri: `${process.env.VUE_APP_API_HOST}/votes/${subjectType}?aliasID=${aliasID}`,
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
