import { Types } from './actions';

export const initState = {
};

export const mutations = {
  [Types.SET_PINNED_FETCHING](state) {
    state.requesting = true;
  },
  [Types.SET_PINNED_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.SET_PINNED_FAILED](state) {
    state.requesting = false;
  },
};
