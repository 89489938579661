import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'SET_PINNED',
  ]),
};

export default {
  [Types.SET_PINNED]({ dispatch }, {
    chatID,
    subjectID,
    isPinned,
    onSuccess = null,
    onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.SET_PINNED),
      method: 'put',
      uri: `${process.env.VUE_APP_API_HOST}/chat/${chatID}/vote/${subjectID}/pinned`,
      data: { isPinned },
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
