import { Base64 } from 'js-base64';
import {
  setTokenToLocalStorage,
} from '@/utils/auth';
import { Types } from './actions';

export const initState = {
  token: null,
  tokenExpiredAt: null,
  aliases: null,
  isAdmin: false,
  requesting: false,
};

export const mutations = {
  [Types.SAVETOKEN](state, payload) {
    state.token = payload.token;
    state.tokenExpiredAt = payload.tokenExpiredAt;

    const t = Base64.encode(state.token);
    const e = Base64.encode(state.tokenExpiredAt);
    setTokenToLocalStorage(JSON.stringify({ t, e }));
  },
  [Types.GET_IS_VOTE_ADMIN_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_IS_VOTE_ADMIN_SUCCESS](state, payload) {
    state.requesting = false;
    state.isAdmin = payload.isVoteAdmin;
  },
  [Types.GET_IS_VOTE_ADMIN_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_ALIASES_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_ALIASES_SUCCESS](state, payload) {
    state.requesting = false;
    state.aliases = payload.aliases;
  },
  [Types.GET_ALIASES_FAILED](state) {
    state.requesting = false;
  },
};
