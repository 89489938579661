import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'GET_VOTE_DATA',
  ]),
};

export default {
  [Types.GET_VOTE_DATA]({ dispatch }, {
    subjectID, onSuccess = null, onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_VOTE_DATA),
      method: 'get',
      uri: `${process.env.VUE_APP_API_HOST}/vote/${subjectID}`,
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
