import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'POST_FILE_UPLOAD',
  ]),
};

export default {
  [Types.POST_FILE_UPLOAD]({ dispatch }, {
    subjectID,
    file,
    onSuccess = null,
    onFailed = null,
  }) {
    const bodyFormData = new FormData();
    bodyFormData.append('subjectID', subjectID);
    bodyFormData.append('file', file);

    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.POST_FILE_UPLOAD),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/vote/file`,
      auth: true,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
