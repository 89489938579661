import { Types } from './actions';

export const initState = {
  subjectID: 0,
};

export const mutations = {
  [Types.GET_NEW_SUBJECT_ID_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_NEW_SUBJECT_ID_SUCCESS](state, payload) {
    state.requesting = false;
    state.subjectID = payload.voteID;
  },
  [Types.GET_NEW_SUBJECT_ID_FAILED](state) {
    state.requesting = false;
  },
};
