import { Types } from './actions';

export const initState = {
  message: null,
  requesting: false,
};

export const mutations = {
  [Types.POST_VOTE_FETCHING](state) {
    state.requesting = true;
  },
  [Types.POST_VOTE_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.POST_VOTE_FAILED](state) {
    state.requesting = false;
  },
};
