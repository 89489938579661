import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import request from '@/utils/requestHelper';
import modules from '@/store/modules';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';
import { genWebApiToken } from '@/utils/tokenGenerator';
//
Vue.use(Vuex);
//
const isProd = process.env.NODE_ENV === 'production';
const plugins = (isProd) ? [] : [createLogger()];

const store = new Vuex.Store({
  devtools: !isProd,
  strict: true,
  modules,
  plugins,
  actions: {
    [API_REQUEST_ACTION_TYPE]({ commit, state }, payload) {
      return new Promise(async (resolve, reject) => {
        const {
          mutationTypes, uri, method, data, oaId, oaToken,
        } = payload;
        const { requesting, success, error } = mutationTypes;
        if (requesting) {
          commit(requesting);
        }
        let { headers } = payload;
        if (uri.startsWith('https://stg-web-api.beanfun.com') || uri.startsWith('https://web-api.beanfun.com')) {
          const token = method.toUpperCase() === 'GET'
            ? genWebApiToken(null, oaId, oaToken)
            : genWebApiToken(data, oaId, oaToken);
          headers = {
            ...headers,
            Authorization: `bearer ${token}`,
          };
        } else if (payload.auth) {
          headers = {
            ...headers,
            Authorization: `Bearer ${state.Auth.token}`,
          };
        }
        // #region 強迫 ajax 成功 (單純測試前端，不串 API 時打開註解，並註解下方 try {} catch (e) {} 程式段)
        /*
        const response = 'todo!! Mock 暫時程式碼 (驗token)';
        const { data } = response;
        commit(success, { ...data, __origin__: payload.extra || null });
        resolve(response);
        console.log(headers);
        console.log(error);
        console.log(reject);
        */
        // #endregion

        try {
          if (process.env.NODE_ENV === 'development') {
            /* eslint-disable-next-line */
            console.log('傳入 API');
            /* eslint-disable-next-line */
            console.log(payload);
          }
          const response = await request({ ...payload, headers });
          if (process.env.NODE_ENV === 'development') {
            /* eslint-disable-next-line */
            console.log('傳出 API');
            /* eslint-disable-next-line */
            console.log(response);
          }
          if (success) {
            commit(success, {
              ...response.data,
              __origin__: payload.extra || null,
            });
          }
          resolve(response);
        } catch (e) {
          if (process.env.NODE_ENV === 'development') {
            /* eslint-disable-next-line */
            console.log('傳出 API-error');
            /* eslint-disable-next-line */
            console.log(e);
          }
          if (error) {
            commit(error, { error: e });
          }
          reject(e);
        }
      });
    },
  },
});

export default store;
