import 'normalize.css';
import Vue from 'vue';
import dayjs from 'dayjs';

import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;
Vue.prototype.dayjs = dayjs;

new Vue({
  router,
  store,
  // eslint-disable-next-line no-unused-vars
  render: h => (
    <div id="app">
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  ),
}).$mount('#app');
