import RouteMeta from './RouteMeta';

export default {
  path: '/voteList',
  name: 'voteList',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/VoteList.vue'),
  props: true,
  meta: new RouteMeta({
    auth: false,
  }),
};
