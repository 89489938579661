import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'GET_IS_VOTED',
  ]),
};

export default {
  [Types.GET_IS_VOTED]({ dispatch }, {
    aliasID,
    subjectID,
    onSuccess = null,
    onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_IS_VOTED),
      method: 'get',
      uri: `${process.env.VUE_APP_API_HOST}/vote/${subjectID}/isVoted?aliasID=${aliasID}`,
      auth: true,
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
