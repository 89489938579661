import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'PUT_EDIT_VOTE',
  ]),
};

export default {
  [Types.PUT_EDIT_VOTE]({ dispatch }, {
    editVote,
    onSuccess = null,
    onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.PUT_EDIT_VOTE),
      method: 'put',
      uri: `${process.env.VUE_APP_API_HOST}/vote/${editVote.subjectID}`,
      auth: true,
      data: editVote.serialize(),
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
