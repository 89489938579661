class VoteListCell {
  constructor({
    subjectID = null,
    subjectTitle = null,
    votePeopleCount = null,
    dueDays = null,
    isVoted = null,
    isEnded = null,
    endTime = null,
  }) {
    this.subjectID = subjectID;
    this.subjectTitle = subjectTitle;
    this.votePeopleCount = votePeopleCount;
    this.dueDays = dueDays;
    this.isVoted = isVoted;
    this.isEnded = isEnded;
    this.endTime = endTime;
  }

  deserialize() {
    return {
      SubjectId: this.SubjectId,
      subjectTitle: this.subjectTitle,
      votePeopleCount: this.votePeopleCount,
      dueDays: this.dueDays,
      isVoted: this.isVoted,
      isEnded: this.isEnded,
    };
  }
}

export default VoteListCell;
