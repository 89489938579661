import {
  ifElse, type, merge, reduce, map, concat, compose,
} from 'ramda';

const typeBuilder = key => ({ [key]: key });
const toUpperCase = str => str.toUpperCase();
const upperCaseList = stringList => map(toUpperCase, stringList);
const typesBuilder = keys => map(typeBuilder, keys);
const mergeElems = elems => ifElse(
  () => type(elems[0]) === 'Array',
  () => reduce(concat, [], elems),
  () => reduce(merge, {}, elems),
)();

export const createType = (
  typeList = [],
) => compose(mergeElems, typesBuilder, upperCaseList)(typeList);

const apiTypeListBuilder = key => [key, `${key}_FETCHING`, `${key}_SUCCESS`, `${key}_FAILED`];
const apiTypesBuilder = types => map(apiTypeListBuilder, types);

export const createApiType = (
  typeList = [],
) => compose(createType, mergeElems, apiTypesBuilder)(typeList);

export const createApiMutationTypes = apiType => ({
  requesting: `${apiType}_FETCHING`,
  success: `${apiType}_SUCCESS`,
  error: `${apiType}_FAILED`,
});
