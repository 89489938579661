import { Types } from './actions';

export const initState = {
  requesting: false,
};

export const mutations = {
  [Types.PUT_EDIT_VOTE_FETCHING](state) {
    state.requesting = true;
  },
  [Types.PUT_EDIT_VOTE_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.PUT_EDIT_VOTE_FAILED](state) {
    state.requesting = false;
  },
};
