import RouteMeta from '../RouteMeta';

export default {
  path: '/news/commentList',
  name: 'commentList',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/news/CommentList.vue'),
  props: true,
  meta: new RouteMeta({
    auth: false,
  }),
};
