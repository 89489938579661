import {
  Question, Option,
} from '@/store/entity/response';
import { Types } from './actions';

export const initState = {
  subjectID: null,
  title: '',
  imageUri: null,
  description: '',
  type: null,
  isActive: null,
  isPinned: null,
  startTime: null,
  endTime: null,
  chatID: null,
  version: 1,
  createdTime: null,
  createdUser: null,
  createdUserName: null,
  modifiedTime: null,
  modifiedUser: null,
  questionList: null,
  votePeopleCount: null,
  dueDays: null,
  isVoted: false,
  isEnded: false,
  requesting: false,
};

export const mutations = {
  [Types.GET_VOTE_RESULT_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_VOTE_RESULT_SUCCESS](state, payload) {
    state.requesting = false;

    state.subjectID = payload.subjectID;
    state.title = payload.title;
    state.imageUri = payload.imageUri;
    state.description = payload.description;
    state.type = payload.type;
    state.isActive = payload.isActive;
    state.isPinned = payload.isPinned;
    state.startTime = payload.startTime;
    state.endTime = payload.endTime;
    state.chatID = payload.chatID;
    state.version = payload.version;
    state.createdTime = payload.createTime;
    state.createdUser = payload.createdUser;
    state.createdUserName = payload.createdUserName;
    state.modifiedTime = payload.modifiedTime;
    state.modifiedUser = payload.modifiedUser;
    state.questionList = [];
    // state.optionList = [];
    state.votePeopleCount = payload.votePeopleCount;
    state.dueDays = payload.dueDays;
    state.isVoted = payload.isVoted;
    state.isEnded = payload.isEnded;

    for (let i = 0; i < payload.questionList.length; i += 1) {
      const question = new Question({
        questionID: payload.questionList[i].questionID,
        title: payload.questionList[i].title,
        mode: payload.questionList[i].mode,
        type: payload.questionList[i].type,
        isMultipleVote: payload.questionList[i].isMultipleVote,
        sortID: payload.questionList[i].sortID,
        optionList: [],
      });

      for (let j = 0; j < payload.questionList[i].optionList.length; j += 1) {
        const option = new Option({
          optionID: payload.questionList[i].optionList[j].optionID,
          title: payload.questionList[i].optionList[j].title,
          imageUri: payload.questionList[i].optionList[j].imageUri,
          sortID: payload.questionList[i].optionList[j].sortID,
          votingRate: Math.round(payload.questionList[i].optionList[j].votingRate * 100),
          isVoted: payload.questionList[i].optionList[j].isSelected,
        });

        question.optionList.push(option);
      }

      state.questionList.push(question);
    }
  },
  [Types.GET_VOTE_RESULT_FAILED](state) {
    state.requesting = false;
  },
};
