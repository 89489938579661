import { Types } from './actions';

export const initState = {
  isVoted: null,
};

export const mutations = {
  [Types.GET_IS_VOTED_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_IS_VOTED_SUCCESS](state, payload) {
    state.requesting = false;
    state.isVoted = payload.isVoted;
  },
  [Types.GET_IS_VOTED_FAILED](state) {
    state.requesting = false;
  },
};
