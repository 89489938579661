import { VoteListCell } from '@/store/entity/response';
import { Types } from './actions';

export const initState = {
  subjectList: [],
  requesting: false,
};

export const mutations = {
  [Types.GET_VOTE_LIST_BY_SUBJECT_TYPE_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_VOTE_LIST_BY_SUBJECT_TYPE_SUCCESS](state, payload) {
    state.requesting = false;

    // 進行初始化動作，避免重複累加
    state.subjectList = [];

    for (let i = 0; i < payload.subjectList.length; i += 1) {
      const cell = new VoteListCell({
        subjectID: payload.subjectList[i].subjectID,
        subjectTitle: payload.subjectList[i].title,
        votePeopleCount: payload.subjectList[i].votePeopleCount,
        dueDays: payload.subjectList[i].dueDays,
        isVoted: payload.subjectList[i].isVoted,
        isEnded: payload.subjectList[i].isEnded,
      });

      state.subjectList.push(cell);
    }
  },
  [Types.GET_VOTE_LIST_BY_SUBJECT_TYPE_FAILED](state) {
    state.requesting = false;
  },
};
