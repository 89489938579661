import { Types } from './actions';

export const initState = {
  isHasPinned: null,
};

export const mutations = {
  [Types.GET_IS_PINNED_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_IS_PINNED_SUCCESS](state, payload) {
    state.requesting = false;
    state.isHasPinned = payload.isHasPinned;
  },
  [Types.GET_IS_PINNED_FAILED](state) {
    state.requesting = false;
  },
};
