import CryptoJS from 'crypto-js';

export const generateRandomString = (stringLength) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < stringLength; i += 1) {
    result += characters.charAt(
      Math.floor(Math.random() * charactersLength),
    );
  }

  return result;
};

export const genWebApiToken = (rawData, oaId, oaToken) => {
  // method 非 GET 時，需傳 rawData
  const rawDataString = rawData ? JSON.stringify(rawData) : '';
  const sha256Hash = CryptoJS.SHA256(rawDataString).toString();
  const dataString = `                ${oaToken}:${sha256Hash}`;
  const key = CryptoJS.enc.Utf8.parse(oaId.slice(0, 32));
  const iv = CryptoJS.enc.Utf8.parse(generateRandomString(16));
  const encrypted = CryptoJS.AES.encrypt(dataString, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const result = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  return result;
};
