import { Types } from './actions';

export const initState = {
  file_uri: null,
  requesting: false,
};

export const mutations = {
  [Types.POST_FILE_UPLOAD_FETCHING](state) {
    state.requesting = true;
  },
  [Types.POST_FILE_UPLOAD_SUCCESS](state, payload) {
    state.requesting = false;
    state.file_uri = payload.file_uri;
  },
  [Types.POST_FILE_UPLOAD_FAILED](state) {
    state.requesting = false;
  },
};
