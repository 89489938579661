import RouteMeta from './RouteMeta';

export default {
  path: '/editVote',
  name: 'editVote',
  component: () => import(/* webpackChunkName: "login" */ '@/pages/EditVote.vue'),
  props: true,
  meta: new RouteMeta({
    auth: false,
  }),
};
