import Vue from 'vue';
import Router from 'vue-router';
import {
  Vote,
  VoteList,
  CreateVote,
  VoteResult,
  EditVote,
  Preview,
  NewsVoteList,
  NewsVote,
  NewsVoteResult,
  CommentList,
} from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    Vote,
    VoteList,
    CreateVote,
    VoteResult,
    EditVote,
    Preview,
    NewsVoteList,
    NewsVote,
    NewsVoteResult,
    CommentList,
    { path: '*', redirect: VoteList },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
