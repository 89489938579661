import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'POST_CREATE_VOTE',
  ]),
};

export default {
  [Types.POST_CREATE_VOTE]({ dispatch }, {
    createVote,
    onSuccess = null,
    onFailed = null,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.POST_CREATE_VOTE),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/vote`,
      auth: true,
      data: createVote.serialize(),
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
