import { createType, createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createType([
    'SAVE_SORT',
    'DELETE_COMMENT_LIST',
    'DELETE_COMMENT_IN_LIST',
    'ADD_REPLY_TO_COMMENT',
    'DELETE_REPLY_IN_COMMENT',
    'CHANGE_COMMENT_MY_REACTION',
  ]),
  ...createApiType([
    'GET_LIKE_COUNT',
    'GET_COMMENT_COUNT',
    'GET_COMMENTS_BY_POPULAR',
    'GET_COMMENTS_BY_NEWEST',
    'GET_COMMENTS_BY_IDS',
    'GET_COMMENT',
    'CREATE_COMMENT',
    'DELETE_COMMENT',
    'GET_REPLIES',
    'CREATE_REPLY',
    'DELETE_REPLY',
    'GET_REACTIONS',
    'CREATE_OR_UPDATE_OR_DELETE_REACTION',
    'CREATE_REPORT_DETAIL',
    'CLICK_VOTE_REACTION',
    'CLICK_COMMENT_REACTION',
  ]),
};

export default {
  SAVE_SORT({ commit }, { sort }) {
    commit(Types.SAVE_SORT, { sort });
  },
  DELETE_COMMENT_LIST({ commit }) {
    commit(Types.DELETE_COMMENT_LIST);
  },
  DELETE_COMMENT_IN_LIST({ commit }, { commentId }) {
    commit(Types.DELETE_COMMENT_IN_LIST, { commentId });
  },
  ADD_REPLY_TO_COMMENT({ commit }, { commentId }) {
    commit(Types.ADD_REPLY_TO_COMMENT, { commentId });
  },
  DELETE_REPLY_IN_COMMENT({ commit }, { commentId, replyId }) {
    commit(Types.DELETE_REPLY_IN_COMMENT, { commentId, replyId });
  },
  CHANGE_COMMENT_MY_REACTION({ commit }, { commentId, type, isCancel }) {
    commit(Types.CHANGE_COMMENT_MY_REACTION, { commentId, type, isCancel });
  },
  [Types.GET_COMMENT_COUNT](
    { dispatch },
    {
      id, entityType, entityId, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_COMMENT_COUNT),
      method: 'get',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/comments/${id}/${entityType}/count?entityId=${entityId}`,
      auth: true,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_LIKE_COUNT](
    { dispatch },
    {
      entityType, aliasId, entityIds, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
      mutationTypes: createApiMutationTypes(Types.GET_COMMENT_COUNT),
      method: 'post',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/entity/${entityType}/meta`,
      auth: true,
      data: {
        aliasId,
        entityIds,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_COMMENTS_BY_POPULAR](
    { dispatch },
    {
      id,
      aliasId,
      entityId,
      entityType,
      show,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_COMMENTS_BY_POPULAR),
      method: 'get',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/comments/${id}/${entityType}/popular?show=${show}&aliasId=${aliasId}&entityId=${entityId}`,
      auth: true,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_COMMENTS_BY_NEWEST](
    { dispatch },
    {
      id,
      entityType,
      entityId,
      aliasId,
      prev,
      sort,
      show,
      next,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_COMMENTS_BY_NEWEST),
      method: 'get',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/comments/${id}/${entityType}/newest?prev=${prev}&next=${next}&sort=${sort}&show=${show}&aliasId=${aliasId}&entityId=${entityId}`,
      auth: true,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_COMMENTS_BY_IDS](
    { dispatch },
    {
      appId, commentIds, aliasId, entityId, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_COMMENTS_BY_IDS),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/comments/${appId}/vote/ids`,
      auth: true,
      data: {
        commentIds,
        aliasId,
        entityId,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_COMMENT](
    { dispatch },
    {
      appId, commentIds, aliasId, entityId, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_COMMENT),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/comments/${appId}/vote/ids`,
      auth: true,
      data: {
        commentIds,
        aliasId,
        entityId,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CREATE_COMMENT](
    { dispatch },
    {
      appId,
      aliasId,
      entityId,
      content,
      userMentions,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CREATE_COMMENT),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/comment/${appId}/vote`,
      auth: true,
      data: {
        aliasId,
        entityId,
        content,
        userMentions,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.DELETE_COMMENT](
    { dispatch },
    {
      appId, aliasId, entityId, commentId, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.DELETE_COMMENT),
      method: 'delete',
      uri: `${process.env.VUE_APP_API_HOST}/comment/${appId}/vote/${commentId}?aliasId=${aliasId}&entityId=${entityId}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_REPLIES](
    { dispatch },
    {
      id,
      entityType,
      commentId,
      aliasId,
      prev,
      next,
      show,
      sort,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_REPLIES),
      method: 'get',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/comment/${id}/${entityType}/replies?prev=${prev}&next=${next}&sort=${sort}&show=${show}&aliasId=${aliasId}&commentId=${commentId}`,
      auth: true,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CREATE_REPLY](
    { dispatch },
    {
      appId,
      aliasId,
      commentId,
      content,
      userMentions,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CREATE_REPLY),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/reply/${appId}/${commentId}`,
      auth: true,
      data: {
        aliasId,
        content,
        userMentions,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.DELETE_REPLY](
    { dispatch },
    {
      appId, aliasId, replyId, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.DELETE_COMMENT),
      method: 'delete',
      uri: `${process.env.VUE_APP_API_HOST}/reply/${appId}/${replyId}?aliasId=${aliasId}`,
      auth: true,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.GET_REACTIONS](
    { dispatch },
    {
      id,
      lotterId,
      lotterType,
      aliasId,
      prev,
      next,
      sort,
      show,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.GET_REACTIONS),
      method: 'get',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/reaction/${id}?prev=${prev}&next=${next}&sort=${sort}&show=${show}&aliasId=${aliasId}&lotterId=${lotterId}&lotterType=${lotterType}`,
      auth: true,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CREATE_OR_UPDATE_OR_DELETE_REACTION](
    { dispatch },
    {
      appId,
      aliasId,
      lotterId,
      lotterType,
      type,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(
        Types.CREATE_OR_UPDATE_OR_DELETE_REACTION,
      ),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/reaction/${appId}`,
      auth: true,
      data: {
        aliasId,
        lotterId,
        lotterType,
        type,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CREATE_REPORT_DETAIL](
    { dispatch },
    {
      appId,
      reporterId,
      lotterId,
      lotterType,
      type,
      reason,
      onSuccess = null,
      onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CREATE_REPORT_DETAIL),
      method: 'post',
      uri: `${process.env.VUE_APP_API_HOST}/reportDetail/${appId}`,
      auth: true,
      data: {
        reporterId,
        lotterId,
        lotterType,
        type,
        reason,
      },
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CLICK_VOTE_REACTION](
    { dispatch },
    {
      entityId, body, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CLICK_VOTE_REACTION),
      method: 'post',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/entity/reaction/${entityId}`,
      auth: true,
      data: body,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
  [Types.CLICK_COMMENT_REACTION](
    { dispatch },
    {
      id, body, onSuccess = null, onFailed = null,
    },
  ) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.CLICK_COMMENT_REACTION),
      method: 'post',
      uri: `${process.env.VUE_APP_WEB_API_HOST}/v1/reaction/${id}`,
      auth: true,
      data: body,
      oaId: process.env.VUE_APP_OA_ID,
      oaToken: process.env.VUE_APP_OA_TOKEN,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((err) => {
        if (onFailed) {
          onFailed(err);
        }
      });
  },
};
