import { Base64 } from 'js-base64';
import { getItem, clear } from '@/utils/storageHelper';

export const getTokenFromLocalStorage = () => getItem('t');

export const setTokenToLocalStorage = token => localStorage.setItem('t', token);

export const checkAuth = (token) => {
  if (JSON.stringify(token) === '"{}"') {
    // 空物件
    clear();
    return false;
  }
  const decoded = JSON.parse(token);
  decoded.t = Base64.decode(decoded.t);
  decoded.e = Base64.decode(decoded.e);
  if (decoded === null) {
    clear();
    return false;
  }
  if (!decoded.t || decoded.e === 0) {
    clear();
    return false;
  }
  if (decoded.e && decoded.e < Date.now()) {
    clear();
    return false;
  }
  return true;
};
