import { Types } from './actions';

export const initState = {
  sort: 1, // 1:熱門; 2:最新
  count: 0,
  listDataByPopular: null,
  listDataByNewest: null,
  list: [],
  replyResult: null,
  requesting: false,
};

export const mutations = {
  [Types.SAVE_SORT](state, payload) {
    state.sort = payload.sort;
    state.list = [];
    state.listDataByPopular = null;
    state.listDataByNewest = null;
  },
  [Types.DELETE_COMMENT_LIST](state) {
    state.list = [];
    state.listDataByPopular = null;
    state.listDataByNewest = null;
  },
  [Types.DELETE_COMMENT_IN_LIST](state, payload) {
    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.commentId) {
        index = i;
        break;
      }
    }

    state.list.splice(index, 1);
    state.count -= 1;
  },
  [Types.ADD_REPLY_TO_COMMENT](state, payload) {
    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.commentId) {
        index = i;
        break;
      }
    }

    const reply = {
      myReaction: 0,
      reactions: [],
      canEdit: true,
      canDelete: true,
      canReport: false,
      reply: state.replyResult,
    };

    if (!state.list[index].replies) {
      state.list[index].replies = [];
    }
    state.list[index].replies.push(reply);
    state.list[index].replyCount += 1;
  },
  [Types.DELETE_REPLY_IN_COMMENT](state, payload) {
    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.commentId) {
        index = i;
        break;
      }
    }

    let indexReply = 0;
    for (let i = 0; i < state.list[index].replies.length; i += 1) {
      if (state.list[index].replies[i].reply.replyId === payload.replyId) {
        indexReply = i;
        break;
      }
    }

    state.list[index].replies.splice(indexReply, 1);
    state.list[index].replyCount -= 1;
  },
  [Types.CHANGE_COMMENT_MY_REACTION](state, payload) {
    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.commentId) {
        index = i;
        break;
      }
    }

    if (payload.isCancel) {
      state.list[index].myReaction = 0;
      state.list[index].reactions.filter(x => x.type === payload.type)[0].count -= 1;
    } else {
      state.list[index].myReaction = 1;
      const reaction = state.list[index].reactions.filter(x => x.type === payload.type);
      if (!reaction) {
        state.list[index].reactions.push({
          type: payload.type,
          count: 1,
        });
      } else {
        state.list[index].reactions.filter(x => x.type === payload.type)[0].count += 1;
      }
    }
  },
  [Types.GET_COMMENT_COUNT_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_COMMENT_COUNT_SUCCESS](state, payload) {
    state.requesting = false;
    state.count = payload.commentsCount;
  },
  [Types.GET_COMMENT_COUNT_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_COMMENTS_BY_POPULAR_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_COMMENTS_BY_POPULAR_SUCCESS](state, payload) {
    state.requesting = false;

    // 進行初始化動作，避免重複累加
    state.list = [];

    for (let i = 0; i < payload.listComments.length; i += 1) {
      const comment = {
        myReaction: payload.listComments[i].myReaction,
        reactions: payload.listComments[i].reactions,
        canEdit: payload.listComments[i].canEdit,
        canDelete: payload.listComments[i].canDelete,
        canReport: payload.listComments[i].canReport,
        replyCount: payload.listComments[i].replyCount,
        comment: payload.listComments[i].comment,
      };

      state.list.push(comment);
    }

    state.listDataByPopular = {
      listCommentIds: payload.listCommentIds,
    };
  },
  [Types.GET_COMMENTS_BY_POPULAR_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_COMMENTS_BY_NEWEST_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_COMMENTS_BY_NEWEST_SUCCESS](state, payload) {
    state.requesting = false;

    // 進行初始化動作，避免重複累加
    if (payload.prev === '') {
      state.list = [];
    }

    for (let i = 0; i < payload.listComments.length; i += 1) {
      const comment = {
        myReaction: payload.listComments[i].myReaction,
        reactions: payload.listComments[i].reactions,
        canEdit: payload.listComments[i].canEdit,
        canDelete: payload.listComments[i].canDelete,
        canReport: payload.listComments[i].canReport,
        replyCount: payload.listComments[i].replyCount,
        comment: payload.listComments[i].comment,
      };

      state.list.push(comment);
    }

    state.listDataByNewest = {
      next: payload.next,
    };
  },
  [Types.GET_COMMENTS_BY_NEWEST_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_COMMENTS_BY_IDS_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_COMMENTS_BY_IDS_SUCCESS](state, payload) {
    state.requesting = false;

    for (let i = 0; i < payload.listComments.length; i += 1) {
      const comment = {
        myReaction: payload.listComments[i].myReaction,
        reactions: payload.listComments[i].reactions,
        canEdit: payload.listComments[i].canEdit,
        canDelete: payload.listComments[i].canDelete,
        canReport: payload.listComments[i].canReport,
        replyCount: payload.listComments[i].replyCount,
        comment: payload.listComments[i].comment,
      };

      state.list.push(comment);
    }
  },
  [Types.GET_COMMENTS_BY_IDS_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_COMMENT_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_COMMENT_SUCCESS](state, payload) {
    state.requesting = false;

    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.listComments[0].comment.commentId) {
        index = i;
        break;
      }
    }

    state.list[index].myReaction = payload.listComments[0].myReaction;
    state.list[index].reactions = payload.listComments[0].reactions;
    state.list[index].canEdit = payload.listComments[0].canEdit;
    state.list[index].canDelete = payload.listComments[0].canDelete;
    state.list[index].canReport = payload.listComments[0].canReport;
    state.list[index].replyCount = payload.listComments[0].replyCount;
    state.list[index].comment = payload.listComments[0].comment;

    const comment = state.list[index];
    if (comment.triggerWatch) {
      comment.triggerWatch += 1;
    } else {
      comment.triggerWatch = 1;
    }
    state.list.splice(index, 1, comment);
  },
  [Types.GET_COMMENT_FAILED](state) {
    state.requesting = false;
  },
  [Types.CREATE_COMMENT_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CREATE_COMMENT_SUCCESS](state, payload) {
    state.requesting = false;

    const comment = {
      myReaction: 0,
      reactions: [],
      canEdit: true,
      canDelete: true,
      canReport: false,
      replyCount: 0,
      comment: {
        commentId: payload.commentId,
        content: payload.content,
        author: {
          aliasId: payload.author.aliasId,
          name: payload.author.name,
          avatar: payload.author.avatar,
        },
        status: payload.status,
        createdTime: payload.createdTime,
        lastModifiedTime: payload.lastModifiedTime,
        userMentionResults: payload.userMentionResults,
      },
    };

    if (state.sort === 1) {
      state.list.push(comment);
    } else {
      state.list.unshift(comment);
    }
    state.count += 1;
  },
  [Types.CREATE_COMMENT_FAILED](state) {
    state.requesting = false;
  },
  [Types.DELETE_COMMENT_FETCHING](state) {
    state.requesting = true;
  },
  [Types.DELETE_COMMENT_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.DELETE_COMMENT_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_REPLIES_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_REPLIES_SUCCESS](state, payload) {
    state.requesting = false;

    let index = 0;
    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].comment.commentId === payload.commentId) {
        index = i;
        break;
      }
    }

    state.list[index].replies = payload.listRelies;
  },
  [Types.GET_REPLIES_FAILED](state) {
    state.requesting = false;
  },
  [Types.CREATE_REPLY_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CREATE_REPLY_SUCCESS](state, payload) {
    state.requesting = false;
    state.replyResult = payload;
  },
  [Types.CREATE_REPLY_FAILED](state) {
    state.requesting = false;
  },
  [Types.DELETE_REPLY_FETCHING](state) {
    state.requesting = true;
  },
  [Types.DELETE_REPLY_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.DELETE_REPLY_FAILED](state) {
    state.requesting = false;
  },
  [Types.GET_REACTIONS_FETCHING](state) {
    state.requesting = true;
  },
  [Types.GET_REACTIONS_SUCCESS](state, payload) {
    state.requesting = false;

    if (payload.lotterType === 1) {
      let index = 0;
      for (let i = 0; i < state.list.length; i += 1) {
        if (state.list[i].comment.commentId === payload.lotterId) {
          index = i;
          break;
        }
      }

      state.list[index].reactionDetails = payload.listReactions;

      const comment = state.list[index];
      if (comment.triggerWatch) {
        comment.triggerWatch += 1;
      } else {
        comment.triggerWatch = 1;
      }
      state.list.splice(index, 1, comment);
    } else {
      let isFindIndex = false;
      let indexComment = 0;
      let indexReply = 0;

      for (let i = 0; i < state.list.length; i += 1) {
        if (isFindIndex) break;

        if (state.list[i].replies) {
          for (let j = 0; j < state.list[i].replies.length; j += 1) {
            if (state.list[i].replies[j].reply.replyId === payload.lotterId) {
              indexComment = i;
              indexReply = j;
              isFindIndex = true;
              break;
            }
          }
        }
      }

      state.list[indexComment].replies[indexReply].reactionDetails = payload.listReactions;

      const comment = state.list[indexComment];
      if (comment.triggerWatch) {
        comment.triggerWatch += 1;
      } else {
        comment.triggerWatch = 1;
      }
      state.list.splice(indexComment, 1, comment);
    }
  },
  [Types.GET_REACTIONS_FAILED](state) {
    state.requesting = false;
  },
  [Types.CREATE_OR_UPDATE_OR_DELETE_REACTION_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CREATE_OR_UPDATE_OR_DELETE_REACTION_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.CREATE_OR_UPDATE_OR_DELETE_REACTION_FAILED](state) {
    state.requesting = false;
  },
  [Types.CREATE_REPORT_DETAIL_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CREATE_REPORT_DETAIL_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.CREATE_REPORT_DETAIL_FAILED](state) {
    state.requesting = false;
  },

  [Types.CLICK_VOTE_REACTION_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CLICK_VOTE_REACTION_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.CLICK_VOTE_REACTION_FAILED](state) {
    state.requesting = false;
  },

  [Types.CLICK_COMMENT_REACTION_FETCHING](state) {
    state.requesting = true;
  },
  [Types.CLICK_COMMENT_REACTION_SUCCESS](state) {
    state.requesting = false;
  },
  [Types.CLICK_COMMENT_REACTION_FAILED](state) {
    state.requesting = false;
  },
};
