import { createApiType, createApiMutationTypes } from '@/store/types/typeHelper';
import { API_REQUEST_ACTION_TYPE } from '@/store/types';

export const Types = {
  ...createApiType([
    'END_VOTE',
  ]),
};

export default {
  [Types.END_VOTE]({ dispatch }, {
    subjectID,
    modifiedUser,
    onSuccess,
    onFailed,
  }) {
    dispatch(API_REQUEST_ACTION_TYPE, {
      mutationTypes: createApiMutationTypes(Types.END_VOTE),
      method: 'put',
      uri: `${process.env.VUE_APP_API_HOST}/vote/${subjectID}/end`,
      auth: true,
      data: { modifiedUser },
    }).then((response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    }).catch(((err) => {
      if (onFailed) {
        onFailed(err);
      }
    }));
  },
};
